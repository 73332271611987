// Code generated by Slice Machine. DO NOT EDIT.

import dynamic from "next/dynamic";

export const components = {
    default_slice: dynamic(() => import("./DefaultSlice")),
    footer_static_slice: dynamic(() => import("./FooterStaticSlice")),
    help_slice: dynamic(() => import("./HelpSlice")),
    links_slice: dynamic(() => import("./LinksSlice")),
    price_plans_slice: dynamic(() => import("./PricePlansSlice")),
    pricing_header_slice: dynamic(() => import("./PricingHeaderSlice")),
    pricing_plan_features_slice: dynamic(
        () => import("./PricingPlanFeaturesSlice"),
    ),
    venue_carousel_slice: dynamic(() => import("./VenueCarouselSlice")),
};
