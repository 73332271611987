import { getRepositoryEndpoint, createClient, Client } from "@prismicio/client";
import { PrismicDocument } from "@prismicio/types";

function prismicClient(): Client {
    const endpoint = getRepositoryEndpoint("courtsite");
    return createClient(endpoint);
}

function newPrismicClient(): Client {
    const endpoint = getRepositoryEndpoint("courtsite-konsol");
    return createClient(endpoint);
}

export async function getKonsolHomeDoc(): Promise<PrismicDocument> {
    const client = prismicClient();
    return await client.getSingle("konsol_home", {});
}

export async function getKonsolPricingDoc(): Promise<PrismicDocument> {
    const client = prismicClient();
    return await client.getSingle("konsol_pricing", {});
}

export async function getKonsolReferralDoc(): Promise<PrismicDocument> {
    const client = prismicClient();
    return await client.getSingle("konsol_referral", {});
}

export async function getKonsolFooterDoc(): Promise<PrismicDocument> {
    const client = newPrismicClient();
    return await client.getSingle("footer", {});
}

export async function getKonsolPricingNewDoc(): Promise<PrismicDocument> {
    const client = newPrismicClient();
    return await client.getSingle("pricing", {});
}

export async function getKonsolVenueCarouselDoc(): Promise<PrismicDocument> {
    const client = newPrismicClient();
    return await client.getSingle("venue_carousel", {});
}
