import Head from "next/head";
import { Image } from "lib/imgproxy";
import { Carousel } from "antd";
import Link from "next/link";
import { GetStaticProps } from "next";
import { useRouter } from "next/router";
import { PrismicDocument } from "@prismicio/types";
import { RichText, RichTextBlock } from "prismic-reactjs";
import { getKonsolHomeDoc } from "lib/prismic";
import EnquirySendForm from "components/Forms/EnquirySendForm";
import { LogoBusiness } from "assets/vectors/logo";
import { DesktopFooter } from "components/DesktopFooter";
import { CheckUnfilled, MenuAlt1Filled } from "components/Icons";
import * as Popover from "@radix-ui/react-popover";
import { ReactNode, useState } from "react";
import { Button } from "components/Button";
import { cx } from "class-variance-authority";

type PrismicImage = {
    alt?: string;
    copyright?: string;
    dimensions: { width: number; height: number };
    url: string;
};
const Layout = (props: { children?: ReactNode }): JSX.Element => (
    <>
        <Head>
            <title>Home - Courtsite for Business</title>
        </Head>
        <Header />
        {props.children}
        <DesktopFooter />
    </>
);
type HomePageProps = { pageDoc: PrismicDocument };
const HomePage = ({ pageDoc }: HomePageProps): JSX.Element => (
    <main>
        <SectionCover />
        <SectionFeatures />
        <SectionBenefit benefits={pageDoc.data.benefit} />
        <SectionEnquiry />
    </main>
);
HomePage.Layout = Layout;

const Header = (): JSX.Element => {
    const [open, setOpen] = useState(false);
    return (
        <header className="sticky top-0 z-10 h-[80px] bg-white shadow-lg">
            <div className="mx-auto flex h-full max-w-[1206px] items-center justify-between px-4">
                <div className="flex items-center gap-8">
                    <LogoBusiness className="h-[40px]" />
                    <Link href="/pricing">
                        <a className="hidden font-bold underline sm:block">
                            Pricing
                        </a>
                    </Link>
                </div>
                <div className="flex gap-4">
                    <Link href="/login">
                        <a className="hidden font-bold underline sm:block">
                            Business Login
                        </a>
                    </Link>
                    <Popover.Root open={open} onOpenChange={setOpen}>
                        <Popover.Trigger className="flex cursor-pointer items-center justify-center border-none bg-transparent">
                            <MenuAlt1Filled />
                        </Popover.Trigger>
                        <Popover.Content
                            role="menu"
                            align="end"
                            className="z-10 flex flex-col gap-2 rounded-lg border border-solid border-blue-grey-50 bg-white p-4 shadow-lg data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95"
                        >
                            <Link href="/login">
                                <a className="text-primary-900 sm:hidden">
                                    Business Login
                                </a>
                            </Link>
                            <a
                                className="text-primary-900"
                                href="#feature"
                                onClick={() => setOpen(false)}
                            >
                                Features of our Courtsite Software
                            </a>
                            <a
                                className="text-primary-900"
                                href="#why-courtsite"
                                onClick={() => setOpen(false)}
                            >
                                Why Courtsite for Business
                            </a>
                            <a
                                className="text-primary-900"
                                href="#business-enquiry"
                                onClick={() => setOpen(false)}
                            >
                                Contact Us
                            </a>
                            <Link href="/pricing">
                                <a className="text-primary-900 sm:hidden">
                                    Pricing
                                </a>
                            </Link>
                        </Popover.Content>
                    </Popover.Root>
                </div>
            </div>
        </header>
    );
};

const SectionHeader = ({
    title,
    subtitle,
}: {
    title?: ReactNode;
    subtitle?: ReactNode;
}): JSX.Element => (
    <div className="mb-10 flex flex-col gap-2 text-center">
        <h2 className="typography-h2 font-bold text-primary-900">{title}</h2>
        <h3 className="typography-h3 text-primary-900">{subtitle}</h3>
    </div>
);

const bannerImages: {
    key: string;
    desktopImage: string;
    alt: string;
    href: string;
    type: "internal" | "external" | "local";
}[] = [
    {
        key: "pricing-package-plans",
        desktopImage: "/images/pricing_plans_v2.png",
        alt: "Pick the right plan for your business",
        href: "/pricing",
        type: "internal",
    },
    {
        key: "custom-pricing",
        desktopImage: "/images/custom_pricing.svg",
        alt: "Custom pricing options for your customers",
        href: "https://blog.courtsite.my/new-feature-more-pricing-options-for-your-customers/",
        type: "external",
    },
    {
        key: "cover",
        desktopImage: "/images/cover.jpg",
        alt: "Explore our features",
        href: "#feature",
        type: "local",
    },
];

const SectionCover = (): JSX.Element => {
    const router = useRouter();
    const handleImageClick = (
        href: string,
        type: "internal" | "external" | "local",
    ): void => {
        if (type === "external") {
            window.open(href, "_blank");
            return;
        }
        if (type === "local") {
            window.location.href = href;
            return;
        }
        router.push(href);
    };

    return (
        <Carousel autoplay>
            {bannerImages.map((b) => (
                <div
                    className="relative h-[60vw] cursor-pointer md:h-[40vw]"
                    key={b.key}
                >
                    <Image
                        src={b.desktopImage}
                        sizes="(max-width: 400px) 300px, (max-width: 700px) 500px"
                        unoptimized
                        layout="fill"
                        objectFit="cover"
                        alt={b.alt}
                        onClick={() => handleImageClick(b.href, b.type)}
                    />
                </div>
            ))}
        </Carousel>
    );
};

const Section = (props: {
    id: string;
    title: ReactNode;
    subtitle: ReactNode;
    children?: ReactNode;
    bg?: "white" | "grey";
    width?: "sm" | "lg";
}): JSX.Element => (
    <div
        className={cx(
            "relative p-16 px-6",
            props.bg === "white" ? "bg-white" : "bg-blue-grey-50",
        )}
    >
        <div id={props.id} className="absolute top-[-80px]" />
        <SectionHeader title={props.title} subtitle={props.subtitle} />
        <div
            className={cx(
                "mx-auto",
                props.width === "sm" ? "max-w-[700px]" : "max-w-[1170px]",
            )}
        >
            {props.children}
        </div>
    </div>
);

const SectionFeatures = (): JSX.Element => (
    <Section
        id="feature"
        title="What is Courtsite for Business?"
        subtitle="Features of our Courtsite Software"
    >
        <div className="grid grid-cols-1 items-center gap-x-8 gap-y-2 md:grid-cols-2">
            <div className="relative aspect-[5/4] w-full md:col-start-2">
                <Image
                    src="/images/mockup/desktop.jpg"
                    layout="fill"
                    width="100%"
                    objectFit="contain"
                    alt="desktop"
                />
            </div>
            <div className="md:row-start-1">
                <h4 className="typography-h3 mb-3 font-bold text-primary">
                    Courtsite Console
                </h4>
                <div className="mb-4">
                    <FeatureItem>
                        Configurable facility management software with flexible
                        schedular, pricing and booking modules.
                    </FeatureItem>
                    <FeatureItem>
                        Cloud-based technology and secure data storage.
                    </FeatureItem>
                    <FeatureItem>
                        Tailored business data analytics and reports.
                    </FeatureItem>
                </div>
                <h4 className="typography-h3 mb-3 font-bold text-primary">
                    Courtsite Lighting Automation System
                </h4>
                <div className="mb-4">
                    <FeatureItem>
                        Integrated bookings and lightings modules that turn
                        lightings on and off automatically according to
                        bookings.
                    </FeatureItem>
                    <FeatureItem>Remote lightings control system.</FeatureItem>
                </div>
            </div>
        </div>
        <div className="grid grid-cols-1 items-center gap-x-8 gap-y-2 md:grid-cols-2">
            <div className="relative aspect-[5/4] w-full">
                <Image
                    src="/images/mockup/mobile.jpeg"
                    layout="fill"
                    width="100%"
                    objectFit="contain"
                    alt="desktop"
                />
            </div>
            <div>
                <h4 className="typography-h3 mb-3 font-bold text-primary">
                    Courtsite Platform
                </h4>
                <div className="mb-4">
                    <FeatureItem>
                        List your sports facility to receive online bookings and
                        payments from your customers 24/7 wherever they are.
                    </FeatureItem>
                    <FeatureItem>
                        Real-time updates on resource availability with
                        synchronised online and offline bookings.
                    </FeatureItem>
                    <Button
                        variant="primary"
                        asChild
                        className="ml-12 mt-2 w-max px-4 font-bold"
                    >
                        <a
                            href="https://www.courtsite.my"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Explore Courtsite Platform
                        </a>
                    </Button>
                </div>
            </div>
        </div>
    </Section>
);

const FeatureItem = (props: { children?: ReactNode }): JSX.Element => (
    <div className="flex items-baseline gap-8">
        <CheckUnfilled className="size-5 translate-y-1" />
        <div className="flex-1">{props.children}</div>
    </div>
);

const SectionBenefit = ({
    benefits,
}: {
    benefits: {
        title: RichTextBlock[];
        description: RichTextBlock[];
        image: PrismicImage;
    }[];
}): JSX.Element => (
    <Section
        id="why-courtsite"
        title="Why Courtsite for Business?"
        subtitle="Let us tell you why"
        bg="white"
    >
        <div className="grid grid-cols-1 gap-x-4 gap-y-8 md:grid-cols-2 lg:grid-cols-3">
            {benefits.map((b, index) => (
                <div key={index} className="flex flex-col items-center">
                    <div className="relative mb-4 aspect-square w-[140px]">
                        <Image
                            src={b.image.url}
                            alt={b.image.alt}
                            layout="fill"
                        />
                    </div>
                    <div className="typography-h3 mb-2 text-center font-bold text-primary">
                        {RichText.asText(b.title)}
                    </div>
                    <div className="text-center text-primary-900">
                        <RichText render={b.description} />
                    </div>
                </div>
            ))}
        </div>
    </Section>
);

const SectionEnquiry = (): JSX.Element => (
    <Section
        id="business-enquiry"
        title="Interested to find out more?"
        subtitle="Leave us a message and we will get in touch with you shortly."
        width="sm"
    >
        <EnquirySendForm />
    </Section>
);

export const getStaticProps: GetStaticProps<HomePageProps> = async () => {
    const doc = await getKonsolHomeDoc();
    return { props: { pageDoc: doc } };
};

export default HomePage;
